<template>
    <div />
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        getApplyStatus(val) {
            switch (val) {
                case 'draft':
                    return '草稿';

                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
